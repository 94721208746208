import PagesByCategory from './Components/PagesByCategory';
import PagesByCategoryCount from './Components/PagesByCategoryCount';
import PagesByStatus from './Components/PagesByStatus';
import UserCountByRole from './Components/UserCountByRole';
import UserCountByRoleCount from './Components/UserCountByRoleCount';
import UserCountByStatus from './Components/UserCountByStatus';
import { Helmet } from 'react-helmet';
import { getSetting } from '../Settings/ApiSettings';
import { useQuery } from 'react-query';

export default function Dashboard(props) {
  return (
    <>
      <Helmet>
        <title>CMS | Dashboard</title>
      </Helmet>
      <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <PagesByCategoryCount />
        <UserCountByRoleCount />
      </div>
      <div className="grid grid-cols-2 gap-6">
        <UserCountByRole />
        <UserCountByStatus />
        <PagesByCategory />
        <PagesByStatus />
      </div>
    </>
  );
}
