import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Notification from '../../SharedComponents/Notification';
import Trash from '../../SharedComponents/Svg/Trash';

export default function DeleteInstructor(props) {
  const { id } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const deleteInstructorMutation = useMutation(deleteInstructor, {
    onSuccess: () => {
      toast(<Notification message={t('Deleted, Successfully')} />);
      queryClient.invalidateQueries('specializations');
    },
  });

  const onClick = () => {
    deleteInstructorMutation.mutate(id);
  };
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className="bg-red-100 text-red-800 hover:bg-red-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <Trash className="w-5 h-5" />
      </button>
    </>
  );
}

export const deleteInstructor = async (e) => {
  console.log('e', e);
  const data = await axios.post('/InstractorMobile/Delete', {
    id: e,
  });
  return data;
};
